<template>
  <div class="addManage">
    <router-link to="/home/managetabal">
      <el-button type="primary">返 回</el-button>
    </router-link>
    <div class="c1 flex">
      <div class="txt">市场名称:</div>
      <el-select v-model="Form.mk_id" placeholder="请选择" class="w500">
        <el-option
          v-for="item in market"
          :key="item.id"
          :label="item.comp_name"
          :value="item.id"
          @click.native="changesegid"
        >
        </el-option>
      </el-select>
    </div>
    <div class="c2 flex">
      <div class="txt">
        市场分区:
      </div>
      <el-select v-model="Form.seg_id" placeholder="请选择" class="w500">
        <el-option
          v-for="item in segid"
          :key="item.id"
          :label="item.segmentation_title"
          :value="item.id"
          @click.native="changeboothids(item.id)"
        >
        </el-option>
      </el-select>
    </div>
    <div class="c3 flex">
      <div class="txt">所在摊位:</div>
      <el-select
        v-model="Form.booth_ids"
        multiple
        placeholder="请选择"
        class="w500"
      >
        <el-option
          v-for="item in boothids"
          :key="item.id"
          :label="item.booth_numbers"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <!-- <el-button>新增摊位</el-button> -->
    </div>
    <!-- <div class="c4 flex">
      <div class="txt">商户编码:</div>
      <el-input
        class="w500"
        v-model="input"
        placeholder="请输入商户编码"
      ></el-input>
    </div> -->
    <div class="c5 flex">
      <div class="txt">商户名称:</div>
      <el-input
        class="w500"
        v-model="Form.name"
        placeholder="请输入商户名称"
      ></el-input>
    </div>
    <div class="c6 flex">
      <div class="txt">法人代表人(经营户负责人):</div>
      <el-select
        v-model="Form.seller_id"
        placeholder="法人代表人(经营户负责人)"
        class="w500"
      >
        <el-option
          v-for="item in sellerid"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="c7 flex">
      <div class="txt">统一社会信用代码:</div>
      <el-input
        class="w500"
        v-model="Form.reg_id"
        placeholder="请输入商户名称"
      ></el-input>
    </div>
    <div class="c8 flex">
      <div class="txt">备案日期:</div>
      <el-date-picker
        class="w500"
        v-model="Form.record_date"
        type="date"
        format="yyyy 年 MM 月 dd 日"
        value-format="yyyy-MM-dd"
        placeholder="选择日期"
      >
      </el-date-picker>
    </div>
    <div class="c9 flex">
      <div class="txt">
        经营者性质:
      </div>
      <el-select class="w500" v-model="Form.property_id" placeholder="请选择">
        <el-option
          v-for="item in propertyid"
          :key="item.id"
          :label="item.operation_title"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="c10 flex">
      <div class="txt">经营类型:</div>
      <el-select
        v-model="Form.type"
        placeholder="请选择"
        style="width:500px"
        @visible-change="blur"
      >
        <el-option
          v-for="item in type"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="c11 flex">
      <div class="txt">主营产品:</div>
      <div>
        <el-select
          v-model="Form.main_product"
          placeholder="请选择"
          style="width:500px"
          multiple
        >
          <el-option
            v-for="item in mainproduct"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="c12" style="margin-top:20px">
      <div class="title">经营证照上传</div>
      <div class="content" style="margin-top:20px">
        <el-upload
          class="avatar-uploader"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :http-request="uploadSectionFile"
          :before-upload="beforeAvatarUpload"
          style="margin-right:20px"
        >
          <img
            v-if="Form.business_license_img"
            :src="Form.business_license_img"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon">营业执照</i>
        </el-upload>
        <el-date-picker
          v-model="Form.business_license_date"
          type="date"
          placeholder="营业执照有效期至"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          style="margin-right:20px"
        >
        </el-date-picker>
        <el-upload
          class="avatar-uploader"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :http-request="uploadSectionFile1"
          :before-upload="beforeAvatarUpload"
          style="margin-right:20px"
        >
          <img v-if="Form.permit_img" :src="Form.permit_img" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon">许可证</i>
        </el-upload>
        <el-date-picker
          v-model="Form.permit_date"
          type="date"
          placeholder="许可证有效期至"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
    </div>

    <div class="c14 " style="margin-top:20px">
      <h3>收款码</h3>
      <div class="content flex">
        <el-upload
          class="avatar-uploader"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :http-request="uploadSectionFile3"
          :before-upload="beforeAvatarUpload"
          style="margin-right:20px"
        >
          <img
            v-if="Form.wechat_collection_code"
            :src="Form.wechat_collection_code"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon">微信收款码</i>
        </el-upload>
        <el-upload
          class="avatar-uploader"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :http-request="uploadSectionFile8"
          :before-upload="beforeAvatarUpload"
          style="margin-right:20px"
        >
          <img
            v-if="Form.wechat_pay_url"
            :src="Form.wechat_pay_url"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon">微信二维码</i>
        </el-upload>
        <el-upload
          class="avatar-uploader"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :http-request="uploadSectionFile4"
          :before-upload="beforeAvatarUpload"
          style="margin-right:20px"
        >
          <img
            v-if="Form.Alipay_collection_code"
            :src="Form.Alipay_collection_code"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon">支付宝收款码</i>
        </el-upload>
      </div>
    </div>
    <!-- <div style="margin-top:20px">
      <h3>绑定设备</h3>
      <div>
        <span style="margin:0 20px">智能电子秤</span>
        <el-select
          v-model="value"
          multiple
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          :remote-method="remoteMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span style="margin-left:20px">
          <span>已选择智能秤：</span>
          <el-link type="primary" style="margin-right:20px">dp44445415</el-link>
          <el-link type="primary">p54748844</el-link>
        </span>
      </div>
      <div style="margin-top:20px">
        <span style="margin:0 20px">智能显示屏</span>
        <el-select
          v-model="value"
          multiple
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          :remote-method="remoteMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span style="margin-left:20px">
          <span>已选择智能显示屏：</span>
          <el-link type="primary" style="margin-right:20px">dp44445415</el-link>
          <el-link type="primary">p54748844</el-link>
        </span>
      </div>
    </div> -->
    <div class="c13" style="margin-top:20px">
      <h3>摊位照片</h3>
      <div class="content flex">
        <el-upload
          class="avatar-uploader"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :http-request="uploadSectionFile5"
          :before-upload="beforeAvatarUpload"
          style="margin-right:20px"
        >
          <img v-if="img1" :src="img1" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-upload
          class="avatar-uploader"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :http-request="uploadSectionFile6"
          :before-upload="beforeAvatarUpload"
          style="margin-right:20px"
        >
          <img v-if="img2" :src="img2" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-upload
          class="avatar-uploader"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :http-request="uploadSectionFile7"
          :before-upload="beforeAvatarUpload"
          style="margin-right:20px"
        >
          <img v-if="img3" :src="img3" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </div>
    </div>
    <div>
      <el-button style="margin-top:20px" @click="submit" type="primary"
        >提 交</el-button
      >
    </div>
  </div>
</template>

<script>
import { getCategoryList } from '../../../api/productList'
import { upload } from '../../../utile/upload'
import {
  // GETsellerShoPpage,
  POSTsellerShopSubmit,
  GETsearch,
  Getseller
} from '../../../api/manage'
import {
  getmarket,
  GETsearchSegmentation,
  GETsearchbooth,
  operation
} from '../../../api/layout'
export default {
  name: 'addManage',
  data () {
    return {
      checkList: [],
      market: '', // 市场
      segid: '', // 市场分区
      boothids: '', // 摊位
      sellerid: '', // 法人代表人
      propertyid: '', // 经营者性质
      type: [], // 经营类型
      mainproduct: [], // 主营产品
      img1: '',
      img2: '',
      img3: '',
      Form: {
        mk_id: '', // 市场id
        seg_id: '', // 市场所属分类id
        seller_id: '', // 商户id
        booth_ids: '', // 摊位id,多个摊位用英文逗号隔开
        name: '', // 商户名称
        reg_id: '', // 统一社会信用代码
        property_id: '', // 经营者性质
        type: '', // 经营类型：对应的商品id
        record_date: '', // 备案日期
        main_product: '', // 主营产品,子类id,多个用英文逗号隔开
        business_license_date: '', // 营业执照有效期至
        business_license_img: '', // 营业执照
        permit_date: '', // 许可证有效期
        permit_img: '', // 许可证
        booth_imgs: '', // 摊位照片,多个图片用英文（,）逗号隔开
        wechat_collection_code: '', // 微信收款码
        wechat_pay_url: '', // 微信二维码
        Alipay_collection_code: '', // 支付宝收款码
        seller_device: 0, // 智能电子秤：1为有,0为无
        seller_device_string: '', // 智能电子秤id字符串（多个设备用英文逗号隔开比如：1,2）  否
        seller_screen: 0, // 显示屏：1为有,0为无
        seller_screen_string: '' // 显示屏字符串（多个设备用英文逗号隔开比如：1,2） 否
      }
    }
  },
  methods: {
    get_market () {
      getmarket().then(res => {
        this.market = res.data
      })
      // 经营类型
      getCategoryList().then(res => {
        this.type = res.data
      })
      // 经营者性质
      operation().then(res => {
        this.propertyid = res.data
      })
      // 商户
      GETsearch({
        page: '',
        limit: 10000,
        search: ''
      }).then(res => {
        this.sellerid = res.data.data
      })
    },

    blur () {
      this.Form.main_product = ''
      this.mainproduct = ''
      this.type.forEach(item => {
        if (item.id === this.Form.type) {
          this.mainproduct = item.children
        }
      })
    },
    // 市场分区
    changesegid () {
      GETsearchSegmentation(this.Form.mk_id).then(res => {
        this.segid = res.data
      })
    },
    // 所在摊位
    changeboothids (id) {
      id ? (id = id) : (id = '')
      GETsearchbooth(id).then(res => {
        this.boothids = res.data
      })
    },
    // 上传图片的校验
    beforeAvatarUpload (file) {
      const isJPG1 = file.type === 'image/png'
      const isJPG2 = file.type === 'image/jpeg'
      const isJPG3 = file.type === 'image/bmp'
      const isJPG4 = file.type === 'image/gif'
      const img = [isJPG1, isJPG2, isJPG3, isJPG4]
      const some = img.some(item => {
        return item
      })
      if (!some) {
        this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
      }
      return some
    },
    // 获取真实的图片路径
    uploadSectionFile (param) {
      // this.Form.business_license_img = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.Form.business_license_img = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 获取真实的图片路径
    uploadSectionFile1 (param) {
      // this.Form.permit_img = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.Form.permit_img = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 获取真实的图片路径
    uploadSectionFile3 (param) {
      // this.Form.wechat_collection_code = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.Form.wechat_collection_code = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 获取真实的图片路径
    uploadSectionFile4 (param) {
      // this.Form.Alipay_collection_code = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.Form.Alipay_collection_code = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    uploadSectionFile5 (param) {
      // this.img1 = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.img1 = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    uploadSectionFile6 (param) {
      // this.img2 = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.img2 = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    uploadSectionFile7 (param) {
      // this.img3 = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.img3 = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    uploadSectionFile8 (param) {
      // this.img3 = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.Form.wechat_pay_url = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    submit () {
      const data = {
        ...this.Form,
        booth_ids: this.Form.booth_ids.toString(),
        main_product: this.Form.main_product.toString(),
        booth_imgs: this.img1 + ',' + this.img2 + ',' + this.img3
      }
      if (this.$route.query.id) {
        data.id = this.$route.query.id
      } else {
        delete data.id
      }
      POSTsellerShopSubmit(data).then(res => {
        if (res.code * 1 === 200) {
          this.$message.success(`${res.data}`)
          this.$router.replace('/home/managetabal')
        } else {
          this.$message.error(`${res.msg}`)
        }
      })
    }
  },
  mounted () {
    this.get_market()
    if (this.$route.query.id) {
      Getseller(this.$route.query.id).then(async res => {
        this.Form.mk_id = res.data.market_information.id
        await this.changesegid()
        this.Form.seg_id = res.data.segmentation.id
        this.changeboothids()
        const array = []
        res.data.booths.forEach(item => {
          array.push(item.booth_id * 1)
        })
        this.Form.booth_ids = array
        this.Form.name = res.data.name
        this.Form.seller_id = res.data.seller_id
        this.Form.reg_id = res.data.reg_id
        if (res.data.created_at) {
          this.Form.record_date = res.data.created_at.split(' ')[0]
        }
        this.Form.property_id = res.data.property_id
        this.Form.type = res.data.type ? res.data.type.id : ''
        if (res.data.img) {
          this.Form.business_license_img = res.data.img.business_license_img
          this.Form.business_license_date = res.data.img.business_license_date
          this.Form.permit_img = res.data.img.permit_img
          this.Form.wechat_collection_code = res.data.img.wechat_collection_code
          this.Form.Alipay_collection_code = res.data.img.Alipay_collection_code
        }

        this.Form.permit_date = res.data.img
          ? res.data.img.permit_date.split(' ')[0]
          : ''

        const arr = []
        res.data.product_main.forEach(item => {
          if (item) {
            arr.push(item.product_id)
          }
        })
        this.Form.main_product = arr
        if (res.data.img) {
          this.img1 = res.data.img.booth_imgs.split(',')[0]
            ? res.data.img.booth_imgs.split(',')[0]
            : ''
          this.img2 = res.data.img.booth_imgs.split(',')[1]
            ? res.data.img.booth_imgs.split(',')[1]
            : ''
          this.img3 = res.data.img.booth_imgs.split(',')[2]
            ? res.data.img.booth_imgs.split(',')[2]
            : ''
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.addManage {
  .c12 {
    .content {
      display: flex;
      align-items: center;
    }
  }
  .txt {
    width: 200px;
  }
  .flex {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  .w500 {
    width: 500px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.width /deep/ .el-input__inner {
  width: 500px;
}
.avatar-uploader-icon {
  font-size: 14px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
</style>
